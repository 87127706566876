import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const errorPage = () => {
    return (
      <Layout>
          <h1>Chyba 404 - stránka nebyla nalezena.</h1>
          <p>Omlouváme se, ale Vámi požadovaná stránka nebyla nalezena.</p>
          <Link to="/">Vrátit se zpět</Link>
      </Layout>
    );
  };
  
  export default errorPage;